import { proxy, snapshot } from 'valtio'

import { DatasetAndItem } from 'helpers/grouping.helper/grouping.helper'
import { getRoute } from 'helpers/route.helper/route.helper'

type FilterStore = {
  currentActiveTagsPerRoute: { [key: string]: Set<string> } //"followupproduction: ["company/bristad", "fuel/waste"]
  allTagsPerRoute: { [key: string]: Set<string> } //"followupproduction: ["company/bristad", "company/city", "fuel/waste", "fuel/wood""]
  groupingPerRoute: { [key: string]: string } //"followupproduction: "company",
  tagsPerUiconfig: string[][]
  filterComponentPerRoute: { [key: string]: boolean }
}

const filterStore = proxy<FilterStore>({
  currentActiveTagsPerRoute: {},
  allTagsPerRoute: {},
  groupingPerRoute: { followupproduction: 'group', uiconfig: 'group', fuelplan: 'group', optimize: 'group' }, //TODO: default active grouping tag is set as "group" here as Exergi wants it to be so. When another system than Exergi uses grouping and filter, this should be changed to a more dynamic default approach
  tagsPerUiconfig: [],
  filterComponentPerRoute: {},
})

export function setGrouping(tag: string): void {
  const route = getRoute()
  filterStore.groupingPerRoute[route] = tag
}

export function resetGrouping(): void {
  const route = getRoute()
  filterStore.groupingPerRoute[route] = ''
}

export function resetFilterTags(): void {
  filterStore.currentActiveTagsPerRoute = {}
  filterStore.allTagsPerRoute = {}
  filterStore.groupingPerRoute = { followupproduction: 'group', uiconfig: 'group', fuelplan: 'group' }
  filterStore.tagsPerUiconfig = []
  filterStore.filterComponentPerRoute = {}
}

export function addTags(allTagsForUiConfig: string[], route: string): void {
  const filterSnap = snapshot(filterStore)
  const allTagsForRoute = filterSnap.allTagsPerRoute[route] ?? new Set()

  const newTags: string[] = []
  allTagsForUiConfig.forEach((tag) => {
    if (!allTagsForRoute.has(tag)) {
      newTags.push(tag)
    }
  })

  addItemsToSet(allTagsForRoute, newTags)

  filterStore.allTagsPerRoute[route] = allTagsForRoute
  if (
    filterSnap.currentActiveTagsPerRoute[route] === undefined ||
    filterSnap.currentActiveTagsPerRoute[route].size === 0
  ) {
    filterStore.currentActiveTagsPerRoute[route] = allTagsForRoute
  } else if (newTags.length > 0) {
    const currentActiveTags = filterSnap.currentActiveTagsPerRoute[route]
    addItemsToSet(currentActiveTags, newTags)
    filterStore.currentActiveTagsPerRoute[route] = currentActiveTags
  }
}

export function getTags(route: string): { allTags: Set<string>; currentActiveTags: Set<string> } {
  return {
    allTags: filterStore.allTagsPerRoute[route],
    currentActiveTags: filterStore.currentActiveTagsPerRoute[route],
  }
}

type DebugFilterStore = {
  datasetAndItemPerSubTagPerId: {
    [uiConfigId: string]: { [subTag: string]: { [returnId: string]: DatasetAndItem } }
  },
}

export const debugFilterStore = proxy<DebugFilterStore>({
  datasetAndItemPerSubTagPerId: {},
})

function addItemsToSet<T>(set: Set<T>, items: T[]): void {
  items.forEach((item) => set.add(item))
}

export default filterStore
