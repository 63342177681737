import { UI_CONFIG_QUERY_KEY } from 'api/uiConfig/uiConfig.api'
import authStore, { getActiveSystemFromLocalStorage } from 'store/auth/auth'
import { applyDefaultTemplate, getDefaultChildrenIds, initializeTemplates } from 'ui/uiConfig/template/defaultUiConfig'
import { softMigrateUiConfigsToDbS } from 'utils/dbs/dbs.helper'
import { proxy, snapshot } from 'valtio'
import { derive, subscribeKey } from 'valtio/utils'

import { clone, merge } from 'helpers/global.helper/global.helper'
import { queryClient } from 'helpers/queryClient'
import {
  applyAliases,
  mergeUiConfigs,
  mergeWithBaseUiConfig,
  translatedUiConfig,
} from 'helpers/uiConfig.helper/uiConfig.helper'

type UiConfigStore = {
  _activeSystemId: number | null
  editingUid: UiConfigUid | null
  uiConfigs: UiConfigCollection
  activeUidsBySystemId: Record<number, UiConfigUid[]>
  previewCollection: UiConfigPreviewCollection
  versions: Record<UiConfigId, UiConfigUid[]>
  uiConfigEditorDefaultValues: UiConfigAliases
  baseUiConfig: UiConfig | null
  showDebugMenu: boolean
  nrOfUiConfigs: number
  getParsedUiConfig: (uid: UiConfigUid, overrideAlias?: UiConfigAliases, options?: { overrideProps?: Record<string, any> }) => UiConfig | null
  getUnparsedUiConfig: (uid: UiConfigUid, overrideAlias?: UiConfigAliases) => UiConfig | null
  getAnchorUiConfigByType: (type: string) => UiConfig | null
  activeUids: UiConfigUid[]
  idToUiConfig: UiConfigCollectionById
  editingUiConfigChildrenUids: Set<number>
  markHoursAhead: boolean
}

subscribeKey(authStore, `activeSystem`, (system) => {
  const uiConfigStoreSnap = snapshot(uiConfigStore)

  uiConfigStore._activeSystemId = system?.id ?? null
  uiConfigStore.editingUid = null

  // Remove all uiconfigs that are for another system.
  // Keep uiconfigs without systems as they are general and should be used for all systems.
  uiConfigStore.uiConfigs = Object.entries(uiConfigStoreSnap.uiConfigs)
    .filter(([id, uiconfig]) => (!uiconfig.system) || (uiconfig.system === system?.id))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

  queryClient.invalidateQueries(UI_CONFIG_QUERY_KEY)
})

const uiConfigStore = proxy<UiConfigStore>(
  {
    _activeSystemId: getActiveSystemFromLocalStorage()?.id ?? null,
    editingUid: Number(localStorage.getItem(`uiConfig.editingUid`)) || null,
    uiConfigs: {},
    activeUidsBySystemId: {},
    previewCollection: {},
    versions: {},
    uiConfigEditorDefaultValues: {},
    baseUiConfig: null,
    markHoursAhead: false,
    showDebugMenu: localStorage.getItem('uiConfig.showDebugMenu') === 'true',
    get activeUids () {
      return this.activeUidsBySystemId[this._activeSystemId ?? -1] ?? []
    },
    get nrOfUiConfigs() {
      return Object.keys(this.uiConfigs).length
    },
    get idToUiConfig() {
      return Object.entries(this.uiConfigs).reduce(
        (acc, [_uid, uiConfig]) => ({ ...acc, [uiConfig.id]: uiConfig }),
        {} as UiConfigCollectionById
      )
    },
    get getParsedUiConfig() {
      return (
        uid: UiConfigUid,
        overrideAlias?: UiConfigAliases,
        options: { overrideProps?: Record<string, any> } = {}
      ): UiConfig | null => {
        let uiConfig = this.uiConfigs[uid]

        if (!uiConfig) {
          return null
        }

        if (uiConfig.props?.use_default) {
          uiConfig = applyDefaultTemplate(uiConfig)
        }

        const baseUiConfig = this.baseUiConfig
        const baseAlias = (baseUiConfig?.props?.alias as UiConfigAliases) || {}
        const previewedUiConfig = this.previewCollection?.[uiConfig.uid] || {}
        const extendedAndPreviewedUiconfig = mergeUiConfigs(clone(uiConfig), clone(previewedUiConfig))

        if (options.overrideProps) {
          Object.entries(options.overrideProps).forEach(([key, overrideValue]) => {
            extendedAndPreviewedUiconfig.props[key] = clone(overrideValue)
          })
        }

        const extendedWithBaseUiConfig = mergeWithBaseUiConfig(extendedAndPreviewedUiconfig, baseUiConfig)
        const aliases = merge(clone(baseAlias), clone(overrideAlias || {}))

        const parsedUiConfig = applyAliases(extendedWithBaseUiConfig, aliases)

        if (parsedUiConfig.props?.use_default) {
          const defaultChildrenIds = getDefaultChildrenIds(parsedUiConfig.component)
          parsedUiConfig.children_ids = [...parsedUiConfig.children_ids, ...defaultChildrenIds]
        }

        return translatedUiConfig(parsedUiConfig)
      }
    },
    get getUnparsedUiConfig() {
      return (uid: UiConfigUid) => {
        const uiConfig = this.uiConfigs[uid]

        if (!uiConfig) {
          return null
        }

        const previewedUiConfig = this.previewCollection?.[uiConfig.uid] || {}
        const extendedAndPreviewedUiconfig = mergeUiConfigs(clone(uiConfig), clone(previewedUiConfig))

        return extendedAndPreviewedUiconfig
      }
    },
    get getAnchorUiConfigByType() {
      return (type: string) => {
        const uiconfig = Object.values(this.uiConfigs)
          .find((uiconfig) => uiconfig.component === type) as UiConfig

        if (!uiconfig) {
          return null
        }

        return this.getParsedUiConfig(uiconfig.uid)
      }
    },
    get editingUiConfigChildrenUids() {
      const childrenUids: Set<number> = new Set([])
      const uid = this.editingUid
      if (!uid) {
        return childrenUids
      }

      const getChildrenUids = (parentUid: number): Set<number> => {
        const parent = this.uiConfigs[parentUid]
        const uids: Set<number> = new Set([parentUid])

        if (!parent) {
          return uids
        }

        parent.children_ids.forEach((childUid) => {
          const grandChildrenUids = getChildrenUids(childUid)

          grandChildrenUids.forEach((grandChildUid) => {
            uids.add(grandChildUid)
          })
        })

        return uids
      }

      return getChildrenUids(uid)
    },
  }
)

subscribeKey(uiConfigStore, `editingUid`, (uid) => {
  localStorage.setItem(`uiConfig.editingUid`, JSON.stringify(uid))
})

subscribeKey(uiConfigStore, 'showDebugMenu', (showDebugMenu) => {
  localStorage.setItem('uiConfig.showDebugMenu', JSON.stringify(showDebugMenu))
})

export const editingUiConfigStore = derive(
  {
    editingUiConfig: (get) => get(uiConfigStore).getUnparsedUiConfig(get(uiConfigStore).editingUid ?? -1) ?? null,
    editingId: (get) => get(uiConfigStore).getParsedUiConfig(get(uiConfigStore).editingUid ?? -1)?.id ?? null,
  },
  { proxy: uiConfigStore }
)

export function addUiConfig(_uiConfig: UiConfig, autoFocus = true): void {
  const snap = snapshot(uiConfigStore)
  const uiConfig = softMigrateUiConfigsToDbS(_uiConfig)

  uiConfigStore.uiConfigs = {
    ...snap.uiConfigs,
    [uiConfig.uid]: uiConfig,
  }

  if (autoFocus) {
    uiConfigStore.editingUid = uiConfig.uid
  }

  if (uiConfig.component === `base_config`) {
    uiConfigStore.baseUiConfig = uiConfig
  }
}

export function addUiConfigs(_newUiConfigs: UiConfig[]): void {
  const snap = snapshot(uiConfigStore)
  const newUiConfigs = softMigrateUiConfigsToDbS(_newUiConfigs)

  // By UID
  const uiConfigsById = newUiConfigs.reduce(
    (acc, uiConfig) => ({
      ...acc,
      [uiConfig.uid]: uiConfig,
    }),
    {} as UiConfigCollection
  )
  const uiConfigCollection = {
    ...snap.uiConfigs,
    ...uiConfigsById,
  }
  uiConfigStore.uiConfigs = uiConfigCollection

  // Get uids for highest versions (only 1 uid for every id)
  const idToHighestVersionAndUidMap: Record<number, { version: number; uid: number }> = {}
  Object.values(uiConfigCollection).forEach((uiConfig) => {
    if ((idToHighestVersionAndUidMap[uiConfig.id]?.version ?? -1) < uiConfig.version) {
      idToHighestVersionAndUidMap[uiConfig.id] = {
        version: uiConfig.version,
        uid: uiConfig.uid,
      }
    }
  })
  const uidsWithHighestVersions: Set<number> = new Set(
    Object.entries(idToHighestVersionAndUidMap).map(([, { uid }]) => uid)
  )

  // By system
  const newActiveUidsBySystemId = Object.values(uiConfigCollection).reduce(
    (acc, uiConfig) => ({
      ...acc,
      [uiConfig.system ?? -1]: [...(acc[uiConfig.system ?? -1] ?? []), uiConfig.uid].filter((uid) =>
        uidsWithHighestVersions.has(uid)
      ),
    }),
    {} as Record<number, number[]>
  )
  const activeUidsBySystemId = {
    ...snap.activeUidsBySystemId,
    ...newActiveUidsBySystemId,
  }
  uiConfigStore.activeUidsBySystemId = activeUidsBySystemId

  // By version
  const versions: Record<UiConfigId, UiConfigUid[]> = {}
  Object.values(uiConfigCollection).forEach((uiConfig) => {
    if (!versions[uiConfig.id]) {
      versions[uiConfig.id] = []
    }

    versions[uiConfig.id].unshift(uiConfig.uid)
  })
  uiConfigStore.versions = {
    ...snap.versions,
    ...versions,
  }

  newUiConfigs.forEach((uiConfig) => {
    if (uiConfig.component === `base_config`) {
      uiConfigStore.baseUiConfig = uiConfig
    }
  })
}

export function removeUiConfigs(id: UiConfigId): void {
  const uiConfigSnap = snapshot(uiConfigStore)
  const removeUids: Set<UiConfigUid> = new Set()
  uiConfigStore.editingUid = null
  delete uiConfigStore.versions[id]
  Object.entries(uiConfigSnap.uiConfigs).forEach(([uid, uiConfig]) => {
    if (uiConfig.id === id) {
      delete uiConfigStore.uiConfigs[uid]
      removeUids.add(Number(uid))
    }
  })
  Object.entries(uiConfigSnap.previewCollection).forEach(([uid, _]) => {
    if (removeUids.has(Number(uid))) {
      delete uiConfigStore.previewCollection[uid]
    }
  })
  Object.entries(uiConfigSnap.activeUidsBySystemId).forEach(([systemId, uids]) => {
    uiConfigStore.activeUidsBySystemId[systemId] = uids.filter(uid => !removeUids.has(uid))
  })
}

initializeTemplates((uiConfig: UiConfig) => addUiConfig(uiConfig, false))
export default uiConfigStore
